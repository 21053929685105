import React, { useEffect, useState } from 'react'
import { ClockLoader, SyncLoader, ClimbingBoxLoader } from 'react-spinners'

const SearchResults = (props) => {
  const [isLoading, setIsLoading] = useState(true)
  const [price, setPrice] = useState(null)
  const [selectedPrice, setSelectedPrice] = useState(null)
  const [showBooking, setShowBooking] = useState(false)
  const [bookingLoading, setBookingLoading] = useState(false)
  const [fullName, setFullName] = useState('')
  const [address, setAddress] = useState('')
  const [userEmail, setEmail] = useState('')
  const [userPhoneNumber, setPhoneNumber] = useState('')
  const [userFlight, setFlight] = useState('')
  const [userMessage, setMessage] = useState('')
  const [error, setError] = useState(null)
  const [sendLoading, setSendLoading] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(null)


  const searchData = props.history.location.state.params



  const checkPrice = () => {
    let ratio = Math.ceil(searchData.passangers / 3)
    let price = 0
    if (searchData.from === 'Airport Sarajevo' && searchData.to === 'Sarajevo') {
      let basePrice = 15

      if (searchData.returnTrip) {
        price = ratio * (2 * basePrice - 5)
      } else {
        price = ratio * basePrice
      }
      // return searchData.returnTrip ? ratio * (2 * basePrice - 5) : ratio * basePrice
    } else if (searchData.from === 'Airport Sarajevo' && searchData.to === 'Visoko') {
      let basePrice = 20
      if (searchData.returnTrip) {
        price = ratio * (2 * basePrice - 5)
      } else {
        price = ratio * basePrice
      }


      // return searchData.returnTrip ? ratio * (2 * basePrice - 5) : ratio * basePrice
    } else if (searchData.from === 'Airport Sarajevo' && searchData.to === 'Konjic') {
      let basePrice = 25
      if (searchData.returnTrip) {
        price = ratio * (2 * basePrice - 5)
      } else {
        price = ratio * basePrice
      }

      // return searchData.returnTrip ? ratio * (2 * basePrice - 5) : ratio * basePrice
    } else if (searchData.from === 'Airport Sarajevo' && searchData.to === 'Jablanica') {
      let basePrice = 30
      if (searchData.returnTrip) {
        price = ratio * (2 * basePrice - 5)
      } else {
        price = ratio * basePrice
      }

      // return searchData.returnTrip ? ratio * (2 * basePrice - 5) : ratio * basePrice
    } else if (searchData.from === 'Airport Sarajevo' && searchData.to === 'Mostar') {
      let basePrice = 50
      if (searchData.returnTrip) {
        price = ratio * (2 * basePrice - 5)
      } else {
        price = ratio * basePrice
      }

      // return searchData.returnTrip ? ratio * (2 * basePrice - 5) : ratio * basePrice
    } else if (searchData.from === 'Airport Sarajevo' && searchData.to === 'Medjugorje') {
      let basePrice = 60
      if (searchData.returnTrip) {
        price = ratio * (2 * basePrice - 5)
      } else {
        price = ratio * basePrice
      }

      // return searchData.returnTrip ? ratio * (2 * basePrice - 5) : ratio * basePrice
    } else if (searchData.from === 'Airport Sarajevo' && searchData.to === 'Tuzla') {
      let basePrice = 50
      if (searchData.returnTrip) {
        price = ratio * (2 * basePrice - 5)
      } else {
        price = ratio * basePrice
      }

      // return searchData.returnTrip ? ratio * (2 * basePrice - 5) : ratio * basePrice
    } else if (searchData.from === 'Airport Sarajevo' && searchData.to === 'Zenica') {
      let basePrice = 30
      if (searchData.returnTrip) {
        price = ratio * (2 * basePrice - 5)
      } else {
        price = ratio * basePrice
      }

      // return searchData.returnTrip ? ratio * (2 * basePrice - 5) : ratio * basePrice
    } else if (searchData.from === 'Airport Sarajevo' && searchData.to === 'Other') {

      price = null
    } else {
      price = null
    }
    if (price) {
      setPrice(price)
    }

    // return price
  }

  const onBookTransfer = (selectedPrice) => {
    setSelectedPrice(selectedPrice)
    setBookingLoading(true)
    setTimeout(() => {
      setBookingLoading(false)
      setShowBooking(true)
    }, 1000)

    // props.history.push('airport', { params: { reservation: searchData, price: selectedPrice } })
  }

  const validateForm = () => {
    if (fullName.trim().length < 1) {
      setError('fullName')
      return false
    } if (address.trim().length < 1) {
      setError('address')
      return false
    } if (userEmail.trim().length < 1) {
      setError('email')
      return false
    } if (userFlight.trim().length < 1) {
      setError('flight')
      return false
    } else {
      setError(null)
      return true
    }
  }

  const sendEmail = (templateId, variables) => {
    setSendLoading(true)

    window.emailjs.send('service_q3cdma8', templateId, variables, 'user_wUiv2JBaQkOPoP3f91WaK')
      .then((res) => {
        setSendLoading(false)
        setShowSuccessMessage(true)
      })
      .catch(err => {
        setShowSuccessMessage(false)
        setSendLoading(false)
      }
      )

  }

  const onCompleteBooking = () => {
    if (validateForm()) {
      const templateId = 'template_1f9ezos';
      let firstName = fullName
      let lastName = selectedPrice
      let email = userEmail
      let date = searchData.departure
      let passangers = searchData.passangers
      let firstAddress = searchData.from
      let lastAddress = `${searchData.to} - ${address}`
      let roundTrip = searchData.returnTrip
      let message = `Flight - ${userFlight}..... Mesagge: ${userMessage}`
      let phoneNumber = userPhoneNumber

      // let sendingObject = {
      //   firstName: firstName,
      //   lastName: lastName,
      //   email: email,
      //   date: date,
      //   passangers: passangers,
      //   emfirstAddressail: firstAddress,
      //   lastAddress: lastAddress,
      //   roundTrip: roundTrip,
      //   message: message,
      //   phoneNumber: phoneNumber,
      // }


      sendEmail(templateId, { firstName, lastName, email, date, passangers, firstAddress, lastAddress, roundTrip, message, phoneNumber, type: `Fast Aiport Search - ${selectedPrice}€` })
    }



  }

  const handleChange = (e) => {
    let name = e.target.name
    let value = e.target.value
    if (name === 'fullName') {
      setFullName(value)
    } else if (name === 'address') {
      setAddress(value)
    } else if (name === 'email') {
      setEmail(value)
    } else if (name === 'phoneNumber') {
      setPhoneNumber(value)
    } else if (name === 'flight') {
      setFlight(value)
    } else if (name === 'message') {
      setMessage(value)
    }
  }

  useEffect(() => {
    checkPrice()
    setTimeout(() => {
      setIsLoading(false)
    }, 2000)
  }, []
  )

  const onClosePopup = (value) => {
    setShowSuccessMessage(null)
    if (value === 'success') {
      props.history.push(`/`);
    }
  }

  if (showSuccessMessage === true) {
    return (
      <div className="detailsContainer">
        <div className="detailsPopup">
          <p className="successHeader">Success</p>
          <p className="successText">Your message has been sent. We will contact you soon.</p>
          <button className="successButton" onClick={() => onClosePopup('success')}>OK</button>
        </div>
        <div className="ui-widget-overlay" />
      </div>
    )
  } else if (showSuccessMessage === false) {
    return (
      <div className="detailsContainer">
        <div className="detailsPopup">
          <p className="failureHeader">Failure</p>
          <p className="successText">Error while trying to send message, please try again or contact us directly on phone or email.</p>
          <button className="failureButton" onClick={() => onClosePopup('failure')}>OK</button>
        </div>
        <div className="ui-widget-overlay" />
      </div>
    )
  } else {
    return sendLoading ? (
      <div style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
        <ClimbingBoxLoader
          color='#5967c3' size={20} loading={sendLoading} />
      </div>
    ) : isLoading ? (
      <div style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
        <ClockLoader color='#5967c3' size={100} loading={isLoading} />
      </div>
    ) : bookingLoading ? (
      <div style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
        <SyncLoader color='#5967c3' size={15} margin={2} loading={bookingLoading} />
      </div>
    ) : showBooking ? (
      <div style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
        <div style={{ backgroundColor: 'white', width: '80%', height: '100%', overflowY: 'auto' }}>
          <p className='purpleColorText' style={{ fontWeight: '1.1em', textAlign: 'center', margin: '1rem' }}>Please fill the form to complete booking</p>
          <div style={{ padding: "1rem" }}>
            <div className="airportFirstName" style={{ marginRight: '0px', marginBottom: '20px' }}>
              <label className='purpleColorText' for="fullName">Full name</label>
              <input style={{ border: "1px solid #5967c3" }} type="text" className="form-control" name="fullName" maxLength="40"
                placeholder="Full name" onChange={handleChange} />
              {error === 'fullName' && <p className="errorMsg">Name is required</p>}
            </div>
            <div className="airportFirstName" style={{ marginRight: '0px', marginBottom: '20px' }}>
              <label className='purpleColorText' for="address">Address (your destination)</label>
              <input style={{ border: "1px solid #5967c3" }} type="text" className="form-control" name="address" maxLength="40"
                placeholder="Address" onChange={handleChange} />
              {error === 'address' && <p className="errorMsg">Address is required</p>}
            </div>

            <div className="airportFirstName" style={{ marginRight: '0px', marginBottom: '20px' }}>
              <label className='purpleColorText' for="email">Email</label>
              <input style={{ border: "1px solid #5967c3" }} type="email" className="form-control" name="email" maxLength="40"
                placeholder="Email" onChange={handleChange} />
              {error === 'email' && <p className="errorMsg">Email is required</p>}
            </div>
            <div className="airportFirstName" style={{ marginRight: '0px', marginBottom: '20px' }}>
              <label className='purpleColorText' for="phoneNumber">Phone Number</label>
              <input style={{ border: "1px solid #5967c3" }} type="number" className="form-control" name="phoneNumber" maxLength="40"
                placeholder="Phone Number" onChange={handleChange} />
            </div>
            <div className="airportFirstName" style={{ marginRight: '0px', marginBottom: '20px' }}>
              <label className='purpleColorText' for="flight">Your flight/ Arrival time</label>
              <input style={{ border: "1px solid #5967c3" }} type="text" className="form-control" name="flight" maxLength="40"
                placeholder="Flight information" onChange={handleChange} />
              {error === 'flight' && <p className="errorMsg">Flight Information is required</p>}
            </div>
            <div className="airportFirstName" style={{ marginRight: '0px', marginBottom: '20px' }}>
              <label className='purpleColorText' for="message">Message</label>
              <textarea style={{ border: "1px solid #5967c3" }} className="form-control" name="message" maxLength="300" id="Textarea1" rows="4" onChange={handleChange}></textarea>
            </div>
            <div className="resultsBtnHolder">
              <div className="selectBtn" style={{ padding: '10px', marginTop: '20px' }} onClick={onCompleteBooking}>
                <p>Complete transfer</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: "100%"
      }}>
        <div style={{
          display: "flex",
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: '20px'
        }}>
          <p className='purpleColorText' style={{ fontWeight: 'bold' }}>Transfer information</p>
        </div>
        <div className="row" style={{
        }}>
          <p className="col-sm purpleColorText">Date: {searchData.departure} {searchData.return ? `- ${searchData.return}` : null}</p>
          <p className="col-sm purpleColorText">From {searchData.from} to {searchData.to}</p>
          <p className="col-sm purpleColorText">Passangers: {searchData.passangers}</p>
        </div>
        <div style={{
          height: '100%',
          width: "100%",
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-evenly',
          borderTop: '1px solid #5967c3'
        }}>
          <div className="searchResultsSingleItem" onClick={() => onBookTransfer(price)}>
            <div className="directionResults">
              <div className="pointsHolder">
                <div className="points">

                </div>
                <div className="line">

                </div>
                <div className="points">

                </div>
              </div>
              <div className="pointsHolder" style={{ justifyContent: 'space-between' }}>
                <p className='purpleColorText' style={{ fontSize: '0.6em', fontWeight: 'bold', textAlign: 'center' }}>{searchData.from}</p>
                <p className='purpleColorText' style={{ fontSize: '0.6em', fontWeight: 'bold', textAlign: 'center' }}>{searchData.to}</p>
              </div>
              <div className="doorToDoor">
                <p style={{ fontSize: '0.6em', fontWeight: 'bold', color: 'white', marginBottom: '0px', textAlign: 'center' }}>DOOR TO DOOR</p>
              </div>
            </div>
            <div className="priceTextResults">
              <p className='purpleColorText' style={{ fontSize: '1em', fontWeight: 'bold', textAlign: "center" }}>Private Transfer</p>
              <p className='purpleColorText' style={{ fontSize: '1em', fontWeight: 'bold', textAlign: "center" }}>{price ? price : 'unknown'}€</p>
              <p className='purpleColorText' style={{ fontSize: '0.6em', textAlign: "center" }}>Total price for {searchData.passangers} passangers</p>
            </div>
            <div className="resultsBtnHolder">
              <div className="selectBtn">
                <p>Book Transfer</p>
              </div>
            </div>
          </div>
          <div className="searchResultsSingleItem" onClick={() => onBookTransfer(price * 1.1)}>
            <div className="directionResults">
              <div className="pointsHolder">
                <div className="points">

                </div>
                <div className="line">

                </div>
                <div className="points">

                </div>
              </div>
              <div className="pointsHolder" style={{ justifyContent: 'space-between' }}>
                <p className='purpleColorText' style={{ fontSize: '0.6em', fontWeight: 'bold', textAlign: 'center' }}>{searchData.from}</p>
                <p className='purpleColorText' style={{ fontSize: '0.6em', fontWeight: 'bold', textAlign: 'center' }}>{searchData.to}</p>
              </div>
              <div className="doorToDoor">
                <p style={{ fontSize: '0.6em', fontWeight: 'bold', color: 'white', marginBottom: '0px', textAlign: 'center' }}>DOOR TO DOOR</p>
              </div>
            </div>
            <div className="priceTextResults">
              <p className='purpleColorText' style={{ fontSize: '1em', fontWeight: 'bold', textAlign: "center" }}>Private Transfer</p>
              <p className='purpleColorText' style={{ fontSize: '1em', fontWeight: 'bold', textAlign: "center" }}>{price ? Math.ceil(price * 1.1) : 'unknown'}€</p>
              <p className='purpleColorText' style={{ fontSize: '0.6em', textAlign: "center" }}>Total price for {searchData.passangers} passangers</p>
            </div>
            <div className="resultsBtnHolder">
              <div className="selectBtn">
                <p>Book Transfer</p>
              </div>
            </div>
          </div>
          <div className="searchResultsSingleItem" onClick={() => onBookTransfer(price * 1.3)}>
            <div className="directionResults">
              <div className="pointsHolder">
                <div className="points">

                </div>
                <div className="line">

                </div>
                <div className="points">

                </div>
              </div>
              <div className="pointsHolder" style={{ justifyContent: 'space-between' }}>
                <p className='purpleColorText' style={{ fontSize: '0.6em', fontWeight: 'bold', textAlign: 'center' }}>{searchData.from}</p>
                <p className='purpleColorText' style={{ fontSize: '0.6em', fontWeight: 'bold', textAlign: 'center' }}>{searchData.to}</p>
              </div>
              <div className="doorToDoor">
                <p style={{ fontSize: '0.6em', fontWeight: 'bold', color: 'white', marginBottom: '0px', textAlign: 'center' }}>DOOR TO DOOR</p>
              </div>
            </div>
            <div className="priceTextResults">
              <p className='purpleColorText' style={{ fontSize: '1em', fontWeight: 'bold', textAlign: "center" }}>Private Transfer</p>
              <p className='purpleColorText' style={{ fontSize: '1em', fontWeight: 'bold', textAlign: "center" }}>{price ? Math.ceil(price * 1.3) : 'unknown'}€</p>
              <p className='purpleColorText' style={{ fontSize: '0.6em', textAlign: "center" }}>Total price for {searchData.passangers} passangers</p>
            </div>
            <div className="resultsBtnHolder">
              <div className="selectBtn">
                <p>Book Transfer</p>
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}

export default SearchResults;
import React, { Component } from 'react';
import { Link } from 'react-router-dom';


import logo from '../img/sarajevoShuttle.png';

class Header extends Component {
  constructor(props) {
    super(props)
  }

  // onLinkcClick = (value) => {
  //   if (value === 'home') {
  //     this.props.history.push('/')
  //   }
  //   if (value === 'airport') {
  //     this.props.history.push('airport')
  //   }
  //   if (value === 'rent') {
  //     this.props.history.push('rent')
  //   }
  //   if (value === 'transfers') {
  //     this.props.history.push('transfers')
  //   }
  //   if (value === 'contacts') {
  //     this.props.history.push('contacts')
  //   }
  // }
  render() {
    return (
      <div className="header" style={{ borderBottom: "1px solid white" }}>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
          <a className="navbar-brand" href="/" style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: '7px'
          }}>
            {/* <img src={logo} width="70" height="50" className="d-inline-block align-top" alt="" /> */}
            <p style={{
              marginBottom: '0px',
              fontWeight: '500',
              fontSize: '12px'
            }}>Sarajevo Airport</p>
            <p style={{
              marginBottom: '0px',
              fontWeight: 'bold',
              fontSize: '14px'
            }}>TRANSFER</p>
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <Link to='/' className="nav-link" >Home</Link>
              </li>
              <li className="nav-item">
                <Link to="/airport" className="nav-link">Airport Shuttle</Link>
              </li>
              <li className="nav-item">
                <Link to="/rent" className="nav-link">Rent a Car</Link>
              </li>
              <li className="nav-item">
                <Link to="/transfers" className="nav-link" >Transfers</Link>
              </li>
              <li className="nav-item">
                <Link to="/contacts" className="nav-link" >Contacts</Link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

export default Header;
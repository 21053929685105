import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Details from '.././Details';
import Mostar from '../../img/mostar.jpg';
import Jajce from '../../img/jajce.jpg';
import Bihac from '../../img/bihac.jpg';
import Travnik from '../../img/travnik.jpg';
import Bjelasnica from '../../img/bjelasnica.jpg';
// import Trebevic from '../../img/trebevic.jpg';
import Sarjevo from '../../img/sarajevo.jpg'

class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDetails: false,
      detailsData: "",
      returnTripSelected: false,
      from: 'Airport Sarajevo',
      to: 'Sarajevo',
      departure: null,
      return: null,
      passangers: null,
      departureError: null,
      returnError: null,
      passangersError: null
    }

    this.handleViewDetails = this.handleViewDetails.bind(this);
    this.closeViewDetails = this.closeViewDetails.bind(this);
    this.onCheckboxClick = this.onCheckboxClick.bind(this);
  }

  handleViewDetails = (e, value) => {
    e.preventDefault();

    this.setState({
      showDetails: true,
      detailsData: value
    })
  }

  closeViewDetails(e) {
    this.setState({
      showDetails: false
    })
  }

  handleChange = (e) => {
    let name = e.target.name
    let value = e.target.value
    if (name === 'passangers' && value < 1) {
      return
    }
    this.setState({ [e.target.name]: e.target.value })
  }

  validateTransferInputs = () => {
    let departure = this.state.departure
    let returnTrip = this.state.return
    if (!departure) {
      this.setState({
        departureError: 'Please enter departure date.',
      })
      return false
    } if (this.state.returnTripSelected && !returnTrip) {
      this.setState({
        returnError: 'Please select return date.'
      })
      return false
    } if (!this.state.passangers) {
      this.setState({
        passangersError: 'Please add passangers.'
      })
      return false
    }
    this.setState({
      departureError: null,
      returnError: null,
      passangersError: null
    })
    return true
  }

  onSearchTransfer = () => {
    if (this.validateTransferInputs()) {
      let searchingObject = {
        from: this.state.from,
        to: this.state.to,
        departure: this.state.departure,
        return: this.state.return,
        passangers: this.state.passangers,
        returnTrip: this.state.returnTripSelected
      }
      this.props.history.push('searchTransfer', { params: searchingObject })
    }

  }

  onOfferClick = (value) => {
    this.props.history.push('transfers', { params: value })
  }

  onCheckboxClick(e) {
    if (e.target.value === 'true') {
      this.setState({
        returnTripSelected: true
      })
    } else {
      this.setState({
        returnTripSelected: false
      })
    }
  }

  onShuttleOfferClick = (value) => {
    this.props.history.push('airport', { params: value })
  }

  render() {
    return (
      <div className="main">
        {this.state.showDetails && (
          <div className="detailsContainer">
            <Details
              data={this.state.detailsData}
              close={e => this.closeViewDetails(e)}
            />
            <div className="ui-widget-overlay" />
          </div>
        )}
        <div className="mainFirst" style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', }}>
          <div className='row' style={{ width: '90%', marginLeft: '5%', marginRight: '5%', paddingLeft: '50px', paddingRight: '50px', minHeight: '100px', marginBottom: '-50px', backgroundColor: "white", border: '2px solid #5967c3' }}>
            <div style={{ height: "50px", display: 'flex', width: '100%', padding: '1%' }}>
              <div style={{
                width: "150px",
                display: 'flex',
                alignItems: 'center'
              }}>
                <input style={{
                  height: '20px',
                  width: '20px',
                }} type="checkbox" checked={!this.state.returnTripSelected} onChange={(e) => this.onCheckboxClick(e)} value={false} name="oneWayTrip" />
                <p style={{ marginBottom: '0px', marginLeft: '20px', fontWeight: 'bold' }}>One way</p>
              </div>
              <div style={{
                width: "150px",
                display: 'flex',
                alignItems: 'center',
                marginLeft: '20px',
              }}>
                <input style={{
                  height: '20px',
                  width: '20px',
                }} type="checkbox" checked={this.state.returnTripSelected} onChange={(e) => this.onCheckboxClick(e)} value={true} name="returnTrip" />
                <p style={{ marginBottom: '0px', marginLeft: '20px', fontWeight: 'bold' }}>Return</p>
              </div>

            </div>
            <div className='row col-md-12 col-lg-4' style={{ backgroundColor: 'white' }}>

              <div className="col-md-6 col-sm-6">
                <div className="airportFirstName" style={{
                  backgroundColor: 'white'
                }}>
                  <label className="labelStyle">From</label>
                  <select type="text" className="form-control inputStyle" name="from" maxLength="20"
                    placeholder="From" onChange={this.handleChange}>
                    <option>Airport Sarajevo</option>
                    {/* <option>Visoko</option>
                    <option>Konjic</option>
                    <option>Jablanica</option>
                    <option>Sarajevo</option>
                    <option>Mostar</option>
                    <option>Medjugorje</option>
                    <option>Tuzla</option>
                    <option>Zenica</option>
                    <option>Mostar</option> */}
                  </select>

                </div>
              </div>
              <div className="col-md-6 col-sm-6">
                <div className="airportFirstName ">
                  <label className="labelStyle">To</label>
                  <select type="text" className="form-control inputStyle" name="to" maxLength="20"
                    placeholder="To" onChange={this.handleChange}>
                    <option>Sarajevo</option>
                    <option>Visoko</option>
                    <option>Konjic</option>
                    <option>Jablanica</option>
                    {/* <option>Airport Sarajevo</option> */}
                    <option>Mostar</option>
                    <option>Medjugorje</option>
                    <option>Tuzla</option>
                    <option>Zenica</option>
                    <option>Other</option>
                  </select>
                </div>
              </div>
            </div>

            <div className='row col-md-12 col-lg-4' style={{ backgroundColor: 'white' }}>
              <div className="col-md-6 col-sm-6">

                <div className="airportFirstName ">
                  <label className="labelStyle" >Departure</label>
                  <input type="date" className="form-control inputStyle" name="departure" maxLength="20" min={new Date().toISOString().split('T')[0]}
                    placeholder="Departure" onChange={this.handleChange} />
                  {this.state.departureError && (<p className="errorMsg">{this.state.departureError}</p>)}
                </div>
              </div>
              {this.state.returnTripSelected && (
                <div className="col-md-6 col-sm-6">
                  <div className="airportFirstName ">
                    <label className="labelStyle" >Return</label>
                    <input type="date" className="form-control inputStyle" name="return" maxLength="20" min={this.state.departure ? this.state.departure : new Date().toISOString().split('T')[0]}
                      placeholder="Return" onChange={this.handleChange} />
                    {this.state.returnError && (<p className="errorMsg">{this.state.returnError}</p>)}
                  </div>
                </div>
              )}

            </div>
            <div className='row col-md-12 col-lg-4' style={{ backgroundColor: 'white' }}>
              <div className="col-md-6 col-sm-12">
                <div className="airportFirstName ">
                  <label className="labelStyle" >Passangers</label>
                  <input type="number" className="form-control inputStyle" style={{ width: '60px' }} name="passangers" maxLength="3" value={this.state.passangers ? this.state.passangers : ''}
                    placeholder="" onChange={this.handleChange} />
                  {this.state.passangersError && (<p className="errorMsg">{this.state.passangersError}</p>)}
                </div>
              </div>
              <div className="col-md-6 col-sm-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="airportFirstName" style={{ margin: "15px" }}>
                  <button onClick={this.onSearchTransfer} className="btn btn-primary" style={{ backgroundColor: '#5967c3' }}>Search Transfers</button>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="mainSecond" style={{ backgroundColor: 'white', marginTop: '50px' }}>
          <h2 className='purpleColorText'>Our services:</h2>
          <div className="row">
            <div className="col-sm box">
              <Link to="/airport">
                <div className="circle a">
                  <i className="fas fa-plane fa-2x"></i>
                </div>
                <h4 className='purpleColorText'>Airport Shuttle</h4>
                <p className='purpleColorText'>As a partner specialized in Sarajevo airport transfers we help you reach your destiantion safely.</p>
              </Link>
            </div>
            <div className="col-sm box">
              <Link to="/rent">
                <div className="circle b">
                  <i className="fas fa-car fa-2x"></i>
                </div>
                <h4 className='purpleColorText'>Rent a Car</h4>
                <p className='purpleColorText'>We can help you search and find a perfect car for your needs.</p>
              </Link>
            </div>
            <div className="col-sm box">
              <Link to="/transfers">
                <div className="circle c">
                  <i className="fas fa-shuttle-van fa-2x"></i>
                </div>
                <h4 className='purpleColorText'>Transfers</h4>
                <p className='purpleColorText'>We can help you with safe and comfortable transfer to any destination.</p>
              </Link>
            </div>
          </div>
        </div>
        <div className="mainSpecial">
          <div className="mainSpecialHeader">
            <h2 className='purpleColorText'>Transfers - Popular routes:</h2>
          </div>
          <div className="mainSpecialMain">
            <div className="row">
              <div onClick={() => this.onShuttleOfferClick('Sarajevo Airport')} className="col-sm boxSpecial " style={{ backgroundColor: "lightblue" }}>
                <div className="labelTextHolder">
                  <p className='purpleColorText'>Private Transfer</p>
                  <p className='purpleColorText'>Door to door</p>
                </div>
                <p className="purpleColorText">Sarajevo Airport - Sarajevo Center</p>
                <div className="boxSpecialText">
                  <img className="img-fluid" alt="" src={Sarjevo} />
                  <p className="purpleColorText">Safe and reliable transfer from airport to your door in Sarajevo</p>
                </div>
                <div className="boxSpecialBtns">
                  <div className="promotionalTextHolder">
                    <p>15€</p><p style={{ textDecoration: "line-through", color: 'gray', marginLeft: '10px', marginRight: "10px" }}>20€</p>
                  </div>

                  <p className='purpleColorText'>More Information {'>'}</p>
                  {/* <button
                    onClick={(e) => this.handleViewDetails(e, "SarajevoAirport")}
                    className="btn btn-primary"
                    style={{ backgroundColor: "#5967c3" }}
                  >S
                    View Details
                  </button> */}
                </div>
              </div>

              <div onClick={() => this.onOfferClick('Mostar - One Day')} className="col-sm boxSpecial" style={{ backgroundColor: "bisque" }}>
                <div className="labelTextHolder2">
                  <p className='purpleColorText'>Private Transfer</p>
                </div>
                <p className='purpleColorText'>Mostar - One Day</p>
                <div className="boxSpecialText">
                  <img className="img-fluid" alt="" src={Mostar} />
                  <p className='purpleColorText'>Sarajevo - Konjic - Mostar - Blagaj - Sarajevo</p>
                  {/* <p className='purpleColorText'>Private Transfer</p>
                  <p className='purpleColorText'>Door to door</p> */}
                </div>
                <div className="boxSpecialBtns" style={{ justifyContent: 'center' }}>
                  <div className="promotionalTextHolder">
                    {/* <p>50€</p><p style={{ textDecoration: "line-through", color: 'gray', marginLeft: '10px', marginRight: "10px" }}>65€</p> */}
                  </div>
                  <p className='purpleColorText'>More Information {'>'}</p>
                  {/* <p className='soldOutText'>SOLD OUT</p> */}
                </div>
              </div>
            </div>
            <div className="row">
              <div onClick={() => this.onOfferClick('Travnik - One Day')} className="col-sm boxSpecial" style={{ backgroundColor: "darkseagreen" }}>
                <div className="labelTextHolder2">
                  <p className='purpleColorText'>Private Transfer</p>
                </div>
                <p className='purpleColorText'>Travnik - One Day</p>
                <div className="boxSpecialText">
                  <img className="img-fluid" alt="Jajce" src={Jajce} />
                  <p className='purpleColorText'>Sarajevo - Travnik - Jajace - Plivsko Lake - Sarajevo </p>
                </div>
                <div className="boxSpecialBtns" style={{ justifyContent: 'center' }}>
                  <div className="promotionalTextHolder">
                    {/* <p>30€</p><p style={{ textDecoration: "line-through", color: 'gray', marginLeft: '10px', marginRight: "10px" }}>40€</p> */}
                  </div>
                  <p className='purpleColorText'>More Information {'>'}</p>
                  {/* <p className='soldOutText'>SOLD OUT</p> */}
                </div>
              </div>
              <div onClick={() => this.onOfferClick('Sarjevo Olympic Mountains - One Day')} className="col-sm boxSpecial" style={{ backgroundColor: "darksalmon" }}>
                <div className="labelTextHolder2">
                  <p className='purpleColorText'>Private Transfer</p>
                </div>
                <p className='purpleColorText'>Sarjevo Olympic Mountains - One Day</p>
                <div className="boxSpecialText">
                  <img className="img-fluid" alt="Bjelasnica" src={Bjelasnica} />
                  <p className='purpleColorText'>Sarajevo - Igman - Bjelasnica - Trebevic - Sarajevo</p>
                </div>
                <div className="boxSpecialBtns" style={{ justifyContent: 'center' }}>
                  <div className="promotionalTextHolder">
                    {/* <p>25€</p><p style={{ textDecoration: "line-through", color: 'gray', marginLeft: '10px', marginRight: "10px" }}>35€</p> */}
                  </div>
                  <p className='purpleColorText'>More Information {'>'}</p>
                  {/* <p className='soldOutText'>SOLD OUT</p> */}
                </div>
              </div>
            </div>
          </div>
        </div >
        <div className="mainThird">
          <h2 className="purpleColorText">
            Other Popular Tours
          </h2>
          <div className="mainSpecialMain">
            <div className="row">
              <div className="col-sm boxSpecial" onClick={() => this.onOfferClick('Vlasic - One Day')}>
                <p style={{
                  borderRadius: "20px",
                  backgroundColor: "#5967c3",
                  color: "white",
                  padding: "10px",
                  height: "45px",
                  marginBottom: "-24px",
                  zIndex: "1"
                }}>Vlasic - One Day</p>
                <div className="boxSpecialText">
                  <img className="img-fluid" alt="" src={Travnik} />
                  <p className="purpleColorText">Sarajevo - Travnik - Vlasic - Sarajevo</p>
                </div>
                {/* <div className="boxSpecialBtns">
                  <button
                    onClick={(e) => this.handleViewDetails(e, "Vlasic")}
                    className="btn btn-primary"
                    style={{ backgroundColor: "#5967c3" }}
                  >
                    View Details
                  </button>
                </div> */}
                <div className="boxSpecialBtns" style={{ justifyContent: 'center' }}>
                  <div className="promotionalTextHolder">
                    {/* <p>50€</p><p style={{ textDecoration: "line-through", color: 'gray', marginLeft: '10px', marginRight: "10px" }}>65€</p> */}
                  </div>
                  <p className='purpleColorText'>More Information {'>'}</p>
                  {/* <p className='soldOutText'>SOLD OUT</p> */}
                </div>
              </div>

              <div className="col-sm boxSpecial" onClick={() => this.onOfferClick('Mostar - Two Days')}>
                <p style={{
                  borderRadius: "20px",
                  backgroundColor: "#5967c3",
                  color: "white",
                  padding: "10px",
                  height: "45px",
                  marginBottom: "-24px",
                  zIndex: "1"
                }}>Mostar - Two Days</p>
                <div className="boxSpecialText">
                  <img className="img-fluid" alt="" src={Mostar} />
                  <p className="purpleColorText">Sarajevo - Konjic - Mostar</p>
                  <p className="purpleColorText">Mostar - Kravice - Blagaj - Sarajevo</p>
                </div>
                <div className="boxSpecialBtns" style={{ justifyContent: 'center' }}>
                  <div className="promotionalTextHolder">
                    {/* <p>100€</p><p style={{ textDecoration: "line-through", color: 'gray', marginLeft: '10px', marginRight: "10px" }}>135€</p> */}
                  </div>
                  <p className='purpleColorText'>More Information {'>'}</p>
                  {/* <p className='soldOutText'>SOLD OUT</p> */}
                </div>
              </div>
              <div className="col-sm boxSpecial" onClick={() => this.onOfferClick('Bihac - Two Days')}>
                <p style={{
                  borderRadius: "20px",
                  backgroundColor: "#5967c3",
                  color: "white",
                  padding: "10px",
                  height: "45px",
                  marginBottom: "-24px",
                  zIndex: "1"
                }}>Bihac - Two Days</p>
                <div className="boxSpecialText">
                  <img className="img-fluid" alt="" src={Bihac} />
                  <p className="purpleColorText">Sarajevo - Travnik - Jajce - Bihac</p>
                  <p className="purpleColorText">Bihac - Strbacki Buk - Martin Brod Waterfalls - Sarajevo</p>
                </div>
                <div className="boxSpecialBtns" style={{ justifyContent: 'center' }}>
                  <div className="promotionalTextHolder">
                    {/* <p>120€</p><p style={{ textDecoration: "line-through", color: 'gray', marginLeft: '10px', marginRight: "10px" }}>180€</p> */}
                  </div>
                  <p className='purpleColorText'>More Information {'>'}</p>
                  {/* <p className='soldOutText'>SOLD OUT</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default Home;